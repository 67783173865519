<template>
  <v-app>
<heade/>
    <!--<mainSect/>
<second/>
<third/>
<four/>
<five/>
<six/>
<seven/>
<eight/>-->
 <router-view></router-view>
 <scripts/>
<fooote/>
 
    <div class="btn-locale-wrapper">
      <v-speed-dial
        v-model="fab"
        :bottom="true"
        :right="true"
        direction="top"
        transition="slide-y-reverse-transition"
        class="btn-locale"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
              <div v-else  class="btn-selected-lang">
                <img 
                :src="selectedLocale.img" 
                :alt="selectedLocale.locale"
              >
              </div>
          </v-btn>
        </template>
        <v-btn 
          fab 
          dark 
          x-small 
          color="indigo"
          v-for="loc in allLocales"
          :key="loc.locale"
          @click="changeLocale(loc)"
          class="btn-locale-item"
        >
          <span class="btn-locale-hover">{{ loc.lang }}</span>
          <div class="locale-img-wrapper">
            <img 
            :src="loc.img" 
            :alt="loc.locale"
          >
          </div>
        </v-btn>
      </v-speed-dial>
    </div>
  </v-app>
</template>

<script>

import heade from "./components/sections/firstSection.vue";
import mainSect from "./components/sections/mainSect.vue";
import second from "./components/sections/second.vue";
import third from "./components/sections/third.vue";
import four from "./components/sections/four.vue";
import five from "./components/sections/five.vue";
import six from "./components/sections/six.vue";
import seven from "./components/sections/seven.vue";
import eight from "./components/sections/eight.vue";
import fooote from "./components/sections/fooote.vue";
import ContactForm from "./components/ContactForm.vue";
import scripts from "../public/sripts.js";

export default {
  name: "App",
  components: {
    scripts,
ContactForm,
heade,
mainSect,
second,
third,
four,
five,
six,
seven,
eight,
fooote
  },
  data() {
    return {
      fab: false,
      locale: null,
      selectedLocale: { 
        lang: "", 
        locale: "", 
        img: "" 
      },
      allLocales: [
       // {
        //  lang: "Russian", 
        //  locale: "ru", 
        //  img: require('@/assets/img/lang/ru.png') 
       // },
        //{
         // lang: "German", 
        //  locale: "de", 
        //  img: require('@/assets/img/lang/de.png') 
       // },
        {
          lang: "English", 
          locale: "en", 
          img: require('@/assets/img/lang/en.png') 
        },
        {
          lang: "Arabic",
          locale: "ar",
          img: require('@/assets/img/lang/ar.png')
        },
        // {
        //   lang: "Swedish", 
        //   locale: "sv", 
        //   img: require('@/assets/img/lang/sv.png') 
        // },
        // {
        //   lang: "Spanish", 
        //   locale: "es", 
        //   img: require('@/assets/img/lang/es.png') 
        // },
        // {
        //   lang: "Italian", 
        //   locale: "it", 
        //   img: require('@/assets/img/lang/it.png') 
        // },
        // {
        //   lang: "French", 
        //   locale: "fr", 
        //   img: require('@/assets/img/lang/fr.png') 
        // },
        // {
        //   lang: "Finnish", 
        //   locale: "fi", 
        //   img: require('@/assets/img/lang/fi.png') 
        // },
        // {
        //   lang: "Dutch", 
        //   locale: "nl", 
        //   img: require('@/assets/img/lang/nl.png') 
        // },
        // {
        //   lang: "Danish", 
        //   locale: "da", 
        //   img: require('@/assets/img/lang/da.png') 
        // }    
      ],
    };
  },
  created() {
    let localeUser = '';
    if (navigator.language) {
      localeUser = navigator.language.substr(0, 2);
    } else {
      localeUser = 'en';
    }
    
    let filteredLocale = this.allLocales.filter(item => {
      if (item.locale == localeUser) {
        return item.locale;
      }
    });

    if (filteredLocale.length > 0) {
      this.locale = filteredLocale[0].locale;
    } else {
      this.locale = 'en';
    }

    console.log(this.locale)

    const selectedLanguage = this.allLocales.filter(lang => lang.locale == this.locale)

    this.selectedLocale = {
      lang: selectedLanguage[0].lang, 
      locale: selectedLanguage[0].locale, 
      img: selectedLanguage[0].img 
    };

    this.$i18n.locale = this.locale;
  },
  methods: {
    changeLocale(loc) {

      this.selectedLocale = {
        lang: loc.lang, 
        locale: loc.locale, 
        img: loc.img 
      };

      this.$i18n.locale = loc.locale;
      localStorage.setItem("locale", loc.locale);
    },
      goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  computed: {
    username() {
      // Мы скоро разберём что такое `params`
      return this.$route.params.username
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");
@import "./assets/style.css";

</style>
