import _en from './en';
import _ru from './ru';
import _de from './de';
import _es from './es';
import _ar from './ar';

const messages = {
  en: _en,
  ru: _ru,
  de: _de,
  es: _es,
  ar: _ar
}

export default messages;
