import Vue from 'vue';
import App from './App.vue';
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueI18n from 'vue-i18n';
import messages from './locale/index';
import VueRouter from 'vue-router';
import router from './router/index';

Vue.use(VueRouter);
Vue.use(VueI18n);
/*
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: "en",
  messages: {
    "en": messages
  }
});
*/

const i18n = new VueI18n({
    locale: 'ru',
    messages: messages
});


Vue.use(VueTelInputVuetify, {
  vuetify,
});


new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');


